import { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';

export const mapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

export const getPercentage = (percent, total) => {
  return (percent / 100) * total;
};

export const useIntersectionObserver = (ref, options) => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIntersecting(true);
        observer.unobserve(ref.current);
      }
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, options]);

  return isIntersecting;
};

export const getOptionImage = option => {
  switch (option) {
    default:
      return 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/12+VEHICLES/CARAVAN_s.png';
  }
};

/**
 * Checks if an array is valid (not null, undefined, or empty).
 * @param {array} arr - The array to check.
 * @returns {boolean} True if the array is valid, false otherwise.
 */
export const isArrayLength = arr => {
  // Check if the input parameter is an array and has a length greater than zero.
  return Array.isArray(arr) && (arr.length > 0 ?? false);
};

export const sortArrayByLabel = arr => {
  const defaultLocale = getDefaultLocale();

  const collator = new Intl.Collator(defaultLocale, { sensitivity: 'base' });

  return (
    isArrayLength(arr) &&
    arr.sort((a, b) => {
      return collator.compare(a.label, b.label);
    })
  );
};

/**
 * Returns a capitalized string.
 * @param {string} str - The string to capitalize.
 * @returns {string|null} The capitalized string, or null if input is invalid.
 */
export const capitalizeFirstLetter = str => {
  if (typeof str !== 'string' || str.length === 0) {
    return null;
  }

  return (str.charAt(0).toUpperCase() + str.slice(1)).trim();
};

export const getDefaultLocale = () => {
  const defaultLocale =
    typeof window !== 'undefined' && window.localStorage.getItem('selectedLanguage');

  return defaultLocale || 'de';
};

export const extractZipAndCity = address => {
  // Split the address into its components
  const parts = address.split(',');

  // If there's not enough information, return null values
  if (parts.length < 2) {
    return {
      zipCode: null,
      city: null,
    };
  }

  // Get the second last element from the address parts
  const secondLastPart = parts[parts.length - 2];
  return secondLastPart;
};

export const extractAddressInfo = async (geolocation, mapboxAccessToken) => {
  try {
    const response = await axios.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${geolocation?.lng},${geolocation?.lat}.json?types=country,region,postcode&access_token=${mapboxAccessToken}`
    );

    const jsonData = response.data;
    const features = jsonData.features;
    if (features.length > 0) {
      const addressComponents = features[0].context;

      let country = '';
      let city = '';

      for (const component of addressComponents) {
        if (component.id.startsWith('country.')) {
          country = component.text;
        }
        if (component.id.startsWith('place.')) {
          city = component.text;
        }
      }

      return { country, city };
    }
  } catch (error) {
    console.error(error);
  }
};

export const getProductImages = images => {
  if (!Array.isArray(images)) {
    return [];
  }
  return images
    .map(
      item =>
        item?.attributes?.variants &&
        (item?.attributes?.variants['scaled-medium']?.url ||
          item?.attributes?.variants['listing-card-2x']?.url)
    )
    .filter(Boolean);
};

export const getStrapiImagesLink = images => {
  // Generate an array of image URLs using the base URL and the image object's URL attribute.
  const imageUrls =
    isArrayLength(images) &&
    images.map(image => {
      const imageUrl = image?.attributes?.url;
      return imageUrl;
    });

  // Return the array of image URLs.
  return imageUrls;
};

// Function to remove duplicates
export const removeDuplicates = arr => {
  const uniqueItems = new Set();

  const uniqueList = arr.filter(item => {
    const measurementValue = item.split('-')[1].split('-')[0];
    if (!uniqueItems.has(measurementValue)) {
      uniqueItems.add(measurementValue);
      return true;
    }
    return false;
  });

  return uniqueList;
};

// Format URI component's query param: { pub_key: 'has_all:a,b,c' }
export const formatStrToQueryStr = (selectedOptions, shouldAddPrefix) => {
  const searchMode = 'has_any';
  const hasOptionsSelected = selectedOptions && selectedOptions.length > 0;
  const mode = searchMode && shouldAddPrefix ? `${searchMode}:` : '';
  const value = hasOptionsSelected ? `${mode}${selectedOptions.join(',')}` : null;
  return value;
};

export async function getReverseGeocodingData(lat, lng) {
  const accessToken =
    'pk.eyJ1IjoieWFhdiIsImEiOiJjbDloMzd5bnAwOHg4M3d1amJta3VzM2g1In0.vfA12OKgtzTglvyl-CA-6w';
  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${accessToken}`;

  try {
    const response = await fetch(url);
    const data = await response.json();

    // Extract city, ZIP, and country from the response
    const place = data.features[0].place_name; // Full place name
    const city = data.features[0].context.find(c => c.id.startsWith('place')).text;
    const zip = data.features[0].context.find(c => c.id.startsWith('postcode')).text;
    const country = data.features[0].context.find(c => c.id.startsWith('country')).text;

    return { city, zip, country };
  } catch (error) {
    return { city: '', zip: '', country: '' };
  }
}

export const shuffleArray = arr => {
  const shuffledArray = [...arr];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

export const getBlogTags = blogs => {
  const tags = [];
  for (let i = 0; i < blogs?.length; i++) {
    const blog = blogs[i];
    tags.push(blog?.attributes?.tags);
  }
  const filteredTags = Array.from(new Set(tags.flat()));
  return tags ? filteredTags : [];
};

export const onSendEmail = (body = null, email = 'info@youandaview.com') => {
  const isValidBody = typeof body === 'string';
  if (typeof window !== 'undefined') {
    const encodedBody = isValidBody && encodeURIComponent(body);
    const messageWithBody = isValidBody ? `mailto:${email}?body=${encodedBody}` : `mailto:${email}`;
    window.location.href = messageWithBody;
  }
};

export const keepValidGermanChars = inputString => {
  // This regex matches all characters that aren't part of the German alphabet, numerics or spaces
  const regex = /[^a-zA-Z0-9äöüß ]/g;

  // Remove text enclosed within square brackets followed by a URL enclosed within parentheses
  const updatedString = inputString.replace(/\[([^\]]+)\]\([^)]+\)/g, '$1');

  // Replace all matches with an empty string
  return updatedString.replace(regex, ' ');
};

export const removeLinkAndSpan = inputText => {
  const cleanedText = inputText
    .replace(/\[.*?\]/g, '') // Remove content within []
    .replace(/\(https?:\/\/.*?\)/g, '') // Remove URLs within ()
    .replace(/\(.*?\)/g, ''); // Remove any remaining content within ()
  return cleanedText;
};

export const checkKeywordsInString = (targetString, keywords) => {
  for (let keyword of keywords) {
    if (
      Array.isArray(keywords) &&
      typeof targetString === 'string' &&
      targetString?.includes(keyword)
    ) {
      return true;
    }
  }
  return false;
};

/**
 * Returns an array of wishlist items associated with a user's profile.
 * @param {object} currentUser - The user object.
 * @returns {array} An array of wishlist items, or an empty array if input is invalid or wishlist is missing.
 */
export const getWishlist = currentUser => {
  return (
    (currentUser && currentUser.id && currentUser?.attributes?.profile?.publicData?.wishlist) || []
  );
};

export const isGuestUser = currentUser => {
  return currentUser && currentUser.id && currentUser?.attributes?.profile?.protectedData?.isGuest;
};

export const stackDisabledToLast = (publicKey, options) => {
  const enabledItems = [];
  const disabledItems = [];

  options.forEach(option => {
    const isDisabled = isArrayLength(publicKey) && !publicKey.includes(option.key);
    if (isDisabled) {
      disabledItems.push(option);
    } else {
      enabledItems.push(option);
    }
  });

  return enabledItems.concat(disabledItems);
};

export function calculateTotalWithMinimum(unitPrice, quantity) {
  // Calculate 20% of the unit price
  const twentyPercent = 0.2 * unitPrice;

  // Check if 20% is less than 3.99 EUR
  if (twentyPercent < 3.99) {
    // Add a minimum of 3.99 EUR to the total
    const total = unitPrice * quantity + 3.99 * quantity;
    return total;
  } else {
    // Add 20% to the total
    const total = unitPrice * quantity + twentyPercent * quantity;
    return total;
  }
}

export function addDotsAfterCharacters(inputString, numCharacters) {
  if (typeof inputString !== 'string' || typeof numCharacters !== 'number') {
    return inputString; // Return the input as is if the input is not valid
  }

  if (inputString.length > numCharacters) {
    return inputString.substring(0, numCharacters) + '...';
  } else {
    return inputString; // Return the input as is if it's shorter than numCharacters
  }
}

export function isOneTruthy(value1, value2) {
  return (value1 && !value2) || (!value1 && value2);
}

// Helper export  to check if exactly one of the values is truthy and value1 is a non-empty array
export function isOneTruthyAndValidArray(value1, value2) {
  // Check if value1 is a non-empty array and value2 is falsy
  const isValue1ValidArray = Array.isArray(value1) && value1.length > 0;
  // Check if exactly one of the values is truthy
  return (isValue1ValidArray && !value2) || (!isValue1ValidArray && value2);
}

export const extractSeoCategoryData = data => {
  const title = data?.attributes?.title;
  const description = data?.attributes?.description;
  const content = data?.attributes?.content;
  const heading = data?.attributes?.heading;
  const url = data?.attributes?.url;
  const headingImage = data?.attributes?.headingImage?.data?.attributes?.url;

  return {
    title,
    description,
    content,
    heading,
    headingImage,
    url,
  };
};

/**
 * Display a toast notification based on the message type.
 *
 * @param {string} type The type of toast to display ('success' or 'error').
 * @param {string} message The message to display in the toast.
 * @param {Object} [options] Additional options for customizing the toast appearance and behavior.
 */
export const showToaster = (type, message, options = {}) => {
  const defaultOptions = {
    position: 'top-center',
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
    ...options, // Merge any additional options passed to the function
  };

  // Display the toast based on the type
  if (type === 'success') {
    toast.success(message, defaultOptions);
  } else if (type === 'error') {
    toast.error(message, defaultOptions);
  } else {
    console.warn('showToast called with invalid type. Expected "success" or "error".');
  }
};

export const filterItemsByTitle = (items, searchKeyword) => {
  // Check if searchKeyword is a string and has a minimum length of 1
  if (typeof searchKeyword !== 'string' || searchKeyword.length < 1) {
    return items; // Return items unchanged if searchKeyword is invalid
  }

  const keywords = searchKeyword.toLowerCase().split(' ');

  return items.filter(item => {
    if (!item.attributes.title) return false;
    const title = item.attributes.title.toLowerCase();

    // Check if any of the keywords partially match any part of the title
    return keywords.some(keyword => {
      for (let i = 0; i <= title.length - keyword.length; i++) {
        if (title.slice(i, i + keyword.length) === keyword) {
          return true;
        }
      }
      return false;
    });
  });
};

// Helper function to sort listings by isInfoOnly attribute
export const sortListingsByInfo = listings => {
  return (
    isArrayLength(listings) &&
    listings.sort((a, b) => {
      // Convert isInfoOnly to a number (false -> 0, true -> 1) and compare
      return (
        (a?.attributes?.publicData?.isInfoOnly ? 1 : 0) -
        (b?.attributes?.publicData?.isInfoOnly ? 1 : 0)
      );
    })
  );
};

export const isDevMode = () => {
  return process.env.REACT_APP_MARKETPLACE_ROOT_URL === 'http://localhost:3000';
};

export const getMarketplaceId = () => {
  return isDevMode() ? 'youandaview-dev' : 'youandaview';
};

// Function to encode the UUID to hex
export const encodeUUIDToHex = uuid => {
  const relevantPart = uuid.split('-').pop(); // Gets the last part of the UUID
  let hex = '';
  for (let i = 0; i < relevantPart.length; i++) {
    hex += relevantPart.charCodeAt(i).toString(16);
  }
  return hex;
};

// Function to decode from hex back to string
export const decodeHexToUUID = hexStr => {
  let str = '';
  for (let i = 0; i < hexStr.length; i += 2) {
    str += String.fromCharCode(parseInt(hexStr.substr(i, 2), 16));
  }
  return str;
};

export const formatEndDate = endDate => {
  return moment(endDate)
    .add(1, 'day')
    .startOf('day')
    .toDate();
};

export const createBBox = (center, width = 0.2, height = 0.2) => {
  if (isArrayLength(center)) {
    const halfWidth = width / 2;
    const halfHeight = height / 2;
    const xmin = center[0] - halfWidth;
    const ymin = center[1] - halfHeight;
    const xmax = center[0] + halfWidth;
    const ymax = center[1] + halfHeight;
    return [xmin, ymin, xmax, ymax];
  }
  return null;
};

export const flattenTimeSlots = inputObj => {
  return Object.values(inputObj).reduce((acc, obj) => {
    return acc.concat(obj.timeSlots);
  }, []);
};

export const constructKlarnaSharetribeCheckoutParams = (sessionStorageValues, piParams = {}) => {
  const listing = sessionStorageValues?.listing;
  const bookingDates = sessionStorageValues?.orderData?.bookingDates;
  const bookingStart = bookingDates?.bookingStart;
  const bookingEnd = bookingDates?.bookingEnd;
  const listingId = listing?.id;

  return {
    listingId,
    bookingDates: {
      bookingStart,
      bookingEnd,
    },
    protectedData: {
      unitType: 'night',
      persons: sessionStorageValues?.orderData?.persons,
      services: sessionStorageValues?.orderData?.services,
      piParams,
    },
    persons: sessionStorageValues?.orderData?.persons,
    services: sessionStorageValues?.orderData?.services,
  };
};

export const createSlugLowerCase = name => String(name || 'slug').toLocaleLowerCase();

export const parseFields = array => {
  return array.map(obj => {
    const parsedObj = { ...obj };

    // Check if 'faqs' field needs parsing
    if (typeof obj.faqs === 'string') {
      try {
        parsedObj.faqs = JSON.parse(obj.faqs);
      } catch (e) {
        console.error('Failed to parse faqs:', e);
      }
    }

    // Check if 'translations' field needs parsing
    if (typeof obj.translations === 'string') {
      try {
        parsedObj.translations = JSON.parse(obj.translations);
      } catch (e) {
        console.error('Failed to parse translations:', e);
      }
    }

    return parsedObj;
  });
};

export const sortListingsByInfoOnly = listings => {
  return listings.sort((a, b) => {
    const isInfoOnlyA = a?.attributes?.publicData?.isInfoOnly;
    const isInfoOnlyB = b?.attributes?.publicData?.isInfoOnly;
    return (isInfoOnlyA === false ? -1 : 1) - (isInfoOnlyB === false ? -1 : 1);
  });
};

export const createGeolocationQueryString = currentLocation => {
  const { lat, lon } = currentLocation.center;

  // Generate bounds based on the lat and lon
  const bounds = `${lat + 0.5},${lon + 0.5},${lat - 0.5},${lon - 0.5}`;

  // Construct the query string
  const queryString = `${currentLocation?.title}&bounds=${bounds}&pub_isInfoOnly=false`;

  return queryString;
};

export async function getBoundsFromMapbox(lat, lon) {
  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lon},${lat}.json?access_token=${mapboxAccessToken}&proximity=${lon},${lat}&types=place,locality,region,country`;
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    for (let feature of data.features) {
      if (feature.bbox) {
        const bounds = feature.bbox;
        return {
          ne: {
            lat: bounds[3],
            lng: bounds[2],
          },
          sw: {
            lat: bounds[1],
            lng: bounds[0],
          },
        };
      }
    }

    throw new Error('No bounding box found for any location.');
  } catch (error) {
    console.error('Error fetching bounds:', error);
    return null;
  }
}

const cleanUpObject = obj => {
  let cleanedObj = {};

  const cleanUpString = str => {
    return str
      .replace(/\\n/g, ' ') // Replace \n with a space
      .replace(/#+/g, '') // Remove hash symbols
      .replace(/\s{2,}/g, ' ') // Replace multiple spaces with a single space
      .trim(); // Trim any leading or trailing spaces
  };

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      // Trim spaces from keys and remove any quotes around keys
      let cleanedKey = key.trim().replace(/^["']|["']$/g, '');

      // Try to parse the value if it's a stringified object
      try {
        let parsedValue = JSON.parse(obj[key]);

        // If the parsed value is an object, recursively clean it
        if (
          typeof parsedValue === 'object' &&
          !Array.isArray(parsedValue) &&
          parsedValue !== null
        ) {
          cleanedObj[cleanedKey] = cleanUpObject(parsedValue);
        } else if (typeof parsedValue === 'string') {
          cleanedObj[cleanedKey] = cleanUpString(parsedValue); // Clean string values
        } else {
          cleanedObj[cleanedKey] = parsedValue;
        }
      } catch (e) {
        // If it's not valid JSON, handle it as a normal value
        if (typeof obj[key] === 'string') {
          cleanedObj[cleanedKey] = cleanUpString(obj[key]); // Clean string values
        } else {
          cleanedObj[cleanedKey] = obj[key];
        }
      }
    }
  }

  return cleanedObj;
};

export const cleanUpData = dataArray => {
  return dataArray.map(item => cleanUpObject(item));
};

export function extractName(dataString) {
  const nameMatch = dataString?.match(/name: \"([^\"]+)\"/);
  return nameMatch ? nameMatch[1] : null;
}

export function extendLatLngByRadius(lat, lng, radiusInKM) {
  const radiusInDegrees = radiusInKM / 111.32; // Approx conversion: 1 degree ≈ 111.32KM

  // Calculate southwest (SW) and northeast (NE) points with toFixed(1)
  const sw = {
    lat: parseFloat((lat - radiusInDegrees).toFixed(1)),
    lng: parseFloat((lng - radiusInDegrees).toFixed(1)),
  };
  const ne = {
    lat: parseFloat((lat + radiusInDegrees).toFixed(1)),
    lng: parseFloat((lng + radiusInDegrees).toFixed(1)),
  };

  return { sw, ne };
}
