import { useState, useEffect } from 'react';
import { cleanUpData, showToaster } from '../util/genericHelpers';
import { getJsonDataFromDB } from '../util/api';

export const useLocations = () => {
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await getJsonDataFromDB();
      const cleanedData = cleanUpData(res?.data);
      // Append the new data to the existing locations array
      setLocations(prevLocations => [...prevLocations, ...cleanedData]);
    } catch (error) {
      setError('Error fetching locations');
      showToaster('Error fetching locations');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { locations, loading, error };
};
