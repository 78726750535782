import { searchDataFromDB } from '../util/api';
import { cleanData, cleanNestedStrings, reformatCsvData } from '../util/genericHelpers';
import { setLocationData } from './singleLocationData.duck';

// actionTypes.js
export const FETCH_DATA_REQUEST = 'app/searchPlace/FETCH_DATA_REQUEST';
export const FETCH_DATA_SUCCESS = 'app/searchPlace/FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILURE = 'app/searchPlace/FETCH_DATA_FAILURE';

// actions.js
// Action to request data
export const fetchDataRequest = () => ({
  type: FETCH_DATA_REQUEST,
});

// Action to handle success
export const fetchDataSuccess = data => ({
  type: FETCH_DATA_SUCCESS,
  payload: data,
});

// Action to handle failure
export const fetchDataFailure = error => ({
  type: FETCH_DATA_FAILURE,
  payload: error,
});

// Thunk to handle async API call
export const fetchSearchData = params => {
  return async dispatch => {
    dispatch(fetchDataRequest());
    try {
      const response = await searchDataFromDB(params);
      dispatch(fetchDataSuccess(response?.data));
      dispatch(setLocationData(response?.data[0]));
      return response?.data[0];
    } catch (error) {
      dispatch(fetchDataFailure(error.message));
    }
  };
};

// reducer.js
const initialState = {
  data: [],
  loading: false,
  error: null,
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case FETCH_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
